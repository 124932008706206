<template>
  <div class="card">
    <div class="bg-blue-light p-2">
      <TitleButton :showBtn="false" :title="isDailyCashSummary ? 'Daily Cash & Bank Position' : 'General Ledger'" />

      <DateQuerySetter
          :inEnoughSpace="false"
          btnTitle="Go"
          @onClickGo="() => onClickGo('general')"
          v-if="isDailyCashSummary"
      >
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label"
          >Select Account Head:
          </label>
          <v-select
              v-model="headId"
              :options="accountHeads"
              label="name"
              :reduce="(name) => name.id"
              placeholder="Select head"
          />
        </div>

        <template #bottom>
          <div class="col-1 d-flex align-items-end justify-content-start mt-2">
            <button class="btn btn-primary btn-block waves-effect waves-float waves-light" @click="onClickGo('pdf')">
              Print
            </button>
          </div>
        </template>
      </DateQuerySetter>

      <DateQuerySetter
          :inEnoughSpace="false"
          btnTitle="Go"
          @onClickGo="onClickGo"
          v-else
      >
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label"
          >Select Account Head:
          </label>
          <v-select
              v-model="headId"
              :options="accountHeads"
              label="name"
              :reduce="(name) => name.id"
              placeholder="Select head"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">

            <div class="d-flex align-items-center justify-content-between filter-label">
                <label for="colFormLabel" class="col-form-label">Party: </label>
                <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                    <i class="fas fa-search"></i>
                </span>
            </div>

            <AsyncSelect
                placeholder="Select Party"
                v-model="contactProfile"
                :api-service="fetchContactProfiles"
                :format-label="formatPatientLabel"
            />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label
              for="colFormLabel"
              class="col-form-label"
          >Business</label
          >
          <v-select
              placeholder="Select Business"
              class="w-100"
              :options="businesses"
              label="name"
              :reduce="(name) => name.id"
              v-model="business_id"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <label for="colFormLabel" class="col-form-label">Cost Center: </label>
            <v-select placeholder="Select Cost Centre" v-model="cost_centre_id" :options="costCentres"
                label="name" :reduce="name => name.id" />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <label for="colFormLabel" class="col-form-label">Project</label>
            <v-select placeholder="Select Project" v-model="project_id" :options="projects" label="name"
                :reduce="name => name.id" />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label
              for="colFormLabel"
              class="col-form-label"
          >Voucher Type</label
          >
          <select class="form-control" v-model="voucher_type">
            <option :value="null" selected>Select voucher type</option>
            <option
                v-for="voucherType in Object.keys(homeData.voucher_type)"
                :value="voucherType" :key="voucherType"
            >
              {{ homeData.voucher_type[voucherType] }}
            </option>
          </select>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label
              for="colFormLabel"
              class="col-form-label"
          >txn Type</label
          >
          <select class="form-control" v-model="txn_type">
            <option :value="null" selected>Select txn type</option>
            <option :value="txnType" :key="txnType" v-for="txnType in typeKeys">
              {{ homeData.txn_type[txnType] }}
            </option>
          </select>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Created By: </label>
          <v-select placeholder="Created By" class="w-100" :options="contactList" label="name"
            :reduce="(name) => name.id" v-model="created_by" />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Tag Register</label>
          <v-select
              placeholder="Select Tag Register"
              class="w-100"
              :options="tagRegisters"
              label="name"
              :reduce="name => name.id"
              v-model="tag_register_id"
          />
        </div>
      </DateQuerySetter>
    </div>

    <div class="card mt-1">
      <div v-if="!isDailyCashSummary" class="d-flex justify-content-between px-2">

        <div>

            <table class="head_left">

                  <tr>
                    <td>Account Name </td>
                    <td>
                      : <strong>{{ accounts.name }}</strong>
                    </td>
                  </tr>

                    <tr>
                        <td>Report period </td>
                        <td>
                          : <strong>{{ ledgerHeadData.report_period }}</strong>
                        </td>
                    </tr>

                  <tr>
                      <td>Party name </td>
                      <td>
                        : <strong>{{ ledgerHeadData.party_name }}</strong>
                      </td>
                  </tr>

                    <tr>
                        <td>Business </td>
                        <td>
                          : <strong>{{ ledgerHeadData.bussiness }}</strong>
                        </td>
                    </tr>

                  <tr>
                      <td>Cost Centre </td>
                      <td>
                        : <strong>{{ ledgerHeadData.cost_centre }}</strong>
                      </td>
                  </tr>

                  <tr>
                      <td>Project </td>
                      <td>
                        : <strong>{{ ledgerHeadData.project }}</strong>
                      </td>
                  </tr>
            </table>
        </div>

        <div>
          <table class="head">
            <tr>
              <td>Opening Balance :</td>
              <td>
                <strong>{{
                    ledgerData.opening_balance
                    ? commaFormat(ledgerData.opening_balance)
                    : 0.0
                  }}</strong>
              </td>
            </tr>
            <tr>
              <td>Total Debit :</td>
              <td>
                <strong>{{
                    ledgerData.total_debit
                    ? commaFormat(ledgerData.total_debit)
                    : 0.0
                  }}</strong>
              </td>
            </tr>
            <tr>
              <td>Total Credit :</td>
              <td>
                <strong>{{
                    ledgerData.total_credit
                    ? commaFormat(ledgerData.total_credit)
                    : 0.0
                  }}</strong>
              </td>
            </tr>
            <tr>
              <td>Closing Balance :</td>
              <td>
                <strong>{{
                    ledgerData.closing_balance
                    ? commaFormat(ledgerData.closing_balance)
                    : 0.0
                  }}</strong>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div v-if="!isDailyCashSummary" class="col-12 mt-2">
        <div class="d-flex justify-content-end px-2">
          <button
              @click="exportTable"
              class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
          >
            Export
          </button>
        </div>
      </div>
      <LedgerTableShort
          v-if="isDailyCashSummary"
          :openingBalance="ledgerData.opening_balance"
          :closingBalance="ledgerData.closing_balance"
          :totalDebitBalance="ledgerData.total_debit"
          :totalCreditBalance="ledgerData.total_credit"
          :currentPage="ledgerData?.ledgers?.current_page"
          :lastPage="ledgerData?.ledgers?.last_page"
          :ledgers="ledgers"
      />
      <LedgerTableOld
          v-else
          :openingBalance="ledgerData.opening_balance"
          :closingBalance="ledgerData.closing_balance"
          :totalDebitBalance="ledgerData.total_debit"
          :totalCreditBalance="ledgerData.total_credit"
          :currentPage="ledgerData?.ledgers?.current_page"
          :lastPage="ledgerData?.ledgers?.last_page"
          :ledgers="ledgers"
      />
    </div>
    <div class="px-2">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
      <ContactDropdownFilter
          v-if="store.state.isModalOpenThree"
          @onSearchContact="onSearchContact"
          :isFilterable="true"
          :companyRoles="companyRoles"
      />
    </div>
    <Loader v-if="reportLoading" />
  </div>
</template>

<script>
import { mapMutations, useStore } from "vuex";
import handleReport from "@/services/modules/accountingReport";
import { inject } from "vue";

import TitleButton from "@/components/atom/TitleButton";
import Loader from "@/components/atom/LoaderComponent";
import DateQuerySetter from "@/components/atom/DateQuerySetter";
import Pagination from "@/components/atom/Pagination";
import figureFormatter from "@/services/utils/figureFormatter";
import handleContact from "@/services/modules/contact";
import handleCBusinesses from "@/services/modules/businesses";

import handleProjects from "@/services/modules/procurement/project";
import handleCostCentres from "@/services/modules/ngo/costCentre";
import handleImportTracker from "@/services/modules/procurement/importTracker";
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleRole from "@/services/modules/role";
import handleTagRegister from "@/services/modules/scm/tag-register";
import LedgerTableOld from "@/components/molecule/company/ledger/LedgerTableOld.vue";
import LedgerTableShort from "@/components/molecule/company/ledger/LedgerTableShort.vue";
import { cloneDeep } from "lodash";

export default {
  name: "GeneralLedger",

  components: {
    LedgerTableOld,
    LedgerTableShort,
    TitleButton,
    DateQuerySetter,
    Pagination,
    Loader,
    ContactDropdownFilter,
    AsyncSelect
  },

  props: {
    onLoadData: {
      type: Function,
      default: () => {}
    }
  },

  emits: ["onLoadData"],

  data: () => ({
    tagRegisters: [],
    contactList: [],
    accountHeads: [],
    ledgerData: {},
    costCentres: [],
    projects: [],
    businesses: [],
    headId: null,
    homeData: {
      txn_type: {},
      voucher_type: {},
    },
    contactProfile: null,
    business_id: null,
    cost_centre_id: null,
    project_id: null,
    voucher_type: null,
    txn_type: null,
    created_by: null,
    tag_register_id: null,
    offset: 20,
    ledgerHeadData: {
      party_name: '',
      bussiness: '',
      cost_centre: '',
      project: '',
      report_period: '',
    },
    companyRoles: []
  }),

  computed: {
    companyId() {
      return this.$route.params.companyId;
    },
    start() {
      return this.$route.query.start;
    },
    end() {
      return this.$route.query.end;
    },
    page() {
      return this.$route.query.page;
    },
    ledgers() {
      const data = this.ledgerData.ledgers && this.ledgerData.ledgers.data ? this.ledgerData.ledgers.data : [];
      return data.map((item) => {
        item.debit = parseFloat(item.debit.replace(/,/g, ""));
        item.credit = parseFloat(item.credit.replace(/,/g, ""));
        item.balance = parseFloat(item.balance.replace(/,/g, ""));
        return item
      });
    },
    accounts() {
      return this.ledgerData.accounts ? this.ledgerData.accounts : {};
    },
    token() {
      return localStorage.getItem("token");
    },
    typeKeys() {
      return Object.keys(this.homeData.txn_type);
    },
    isDailyCashSummary() {
      return this.$route.name === "daily-cash-summary";
    }
  },

  methods: {
    async getCompanyRoles () {
        const res = await this.fetchCompanyDefaultRoles(`?company_id=${this.$route.params.companyId}`);
        if (res.status) {
            this.companyRoles = res.data
        }
    },
    resetledgerHeadData() {
        this.ledgerHeadData = {
            party_name: '',
            bussiness: '',
            cost_centre: '',
            project: '',
            report_period: '',
        }
    },
    setledgerHeadData() {
        this.ledgerHeadData.party_name = this.contactProfile ? this.contactProfile.name : ''
        this.ledgerHeadData.bussiness = this.findData(this.businesses, this.business_id)
        this.ledgerHeadData.cost_centre = this.findData(this.costCentres, this.cost_centre_id)
        this.ledgerHeadData.project = this.findData(this.projects, this.project_id)
        this.ledgerHeadData.report_period = this.start + ' to ' + this.end;
    },
    findData(dataList, value, column = 'id') {
      if (!value) return '';
      const singleData = dataList.find(item => item[column] === value);
      return singleData.name;
    },
    ...mapMutations({
      setPaginationData: "setPaginationData",
      resetPagination: "resetPagination",
    }),

    exportTable() {
      if (!this.headId) {
        this.showError("Please select head");
        return;
      }
      let query = this.getQuery();
      let url = `${process.env.VUE_APP_BASE_URL}/export/reports/general-ledger-old/${this.headId}${query}&_token=${this.token}`;
      let a = document.createElement("a");
      a.setAttribute("href", url);
      a.click();
    },

    setPagination() {
      if (!this.ledgerData?.ledgers?.data) return;
      this.setPaginationData({
        records: this.ledgerData.ledgers.total,
        perPage: this.ledgerData.ledgers.per_page,
        currentPage: this.ledgerData.ledgers.current_page,
      });
    },
    onClickGo(loadType = 'general') {
      this.setPaginationData({});
      let routeQuery = Object.assign({}, this.$route.query);
      routeQuery.page = 1;
      this.$router.push({ path: this.$route.path, query: routeQuery });
      setTimeout(() => {
        this.getDateWiseLedger(loadType);
      }, 100);
    },

    onPageChange(page) {
      let routeQuery = Object.assign({}, this.$route.query);
      routeQuery.page = page;
      this.$router.push({ path: this.$route.path, query: routeQuery });
      setTimeout(() => {
        this.getDateWiseLedger();
      }, 100);
    },

    check() {
      this.$refs.pagination.setPage(2);
    },

    async getAccountHeads() {
      try {
        this.reportLoading = true;
        let res = await this.fetchAccountHeads(this.$route.params.companyId);
        if (!res.status) {
          this.resetPagination();
          this.showError(res.message);
        }
        if (res.status) {
          this.accountHeads = res.data;
        }
      } catch (err) {
        if (!err.response) {
          this.showError("Something is wrong. Check your connectivity!!");
        }
        if (err.response) {
          this.showError(err.response.message);
        }
      } finally {
        this.reportLoading = false;
      }
    },

    async getHome() {
      try {
        let res = await this.fetchHome();
        if (!res.status) {
          //this.showError(res.message)
          this.resetPagination();
          this.homeData = {};
        }
        if (res.status) {
          this.homeData = res.data;
        }
      } catch (err) {
        if (err.response) {
          this.showError(err.response.message);
        }
      }
    },
    async getBusinesses() {
      try {
        let companyId = this.$route.params.companyId;
        let res = await this.fetchBusinessList("?company_id=" + companyId);

        if (res.status) {
          this.businesses = res.data;
        }
      } catch (err) {
        if (err.response) {
          this.showError(err.response.message);
        }
      } finally {
        this.loading = false;
      }
    },

    getQuery() {
      let query = "?company_id=" + this.companyId;
      if (!this.start && !this.end) return query;
      if (this.start) query += "&start_date=" + this.start;
      if (this.end) query += "&end_date=" + this.end;
      if (this.page) query += "&page=" + this.page;
      if (this.contactProfile) query += "&contact_profile_id=" + this.contactProfile.id;
      if (this.business_id) query += "&business_id=" + this.business_id;
      if (this.txn_type) query += "&txn_type=" + this.txn_type;
      if (this.voucher_type) query += "&voucher_type=" + this.voucher_type;
      if (this.cost_centre_id) query += "&cost_centre_id=" + this.cost_centre_id;
      if (this.project_id) query += "&project_id=" + this.project_id;
      if (this.created_by) query += "&created_by=" + this.created_by;
      if (this.tag_register_id) query += "&tag_register_id=" + this.tag_register_id;
      query += "&offset=" + this.offset;
      return query;
    },

    async getDateWiseLedger(loadType = 'general') {
      if (!this.headId) {
        this.showError("Please select head");
        return;
      }

      this.setledgerHeadData();

      let query = this.getQuery();
      if(loadType === 'pdf'){
        query += '&is_all=1'
      }

      try {
        this.reportLoading = true;
        let res = await this.getLedger({
          headId: this.headId,
          query: query,
        });
        if (!res.status) {
          this.showError(res.response.message);
        }
        if (res.status) {
          const resData = res.data;
          resData.opening_balance = resData.opening_balance ? parseFloat(resData.opening_balance.replace(/,/g, '')) : 0;
          resData.total_debit = resData.total_debit ? parseFloat(resData.total_debit.replace(/,/g, '')) : 0;
          resData.total_credit = resData.total_credit ? parseFloat(resData.total_credit.replace(/,/g, '')) : 0;
          resData.closing_balance = resData.closing_balance ? parseFloat(resData.closing_balance.replace(/,/g, '')) : 0;

          this.ledgerData = res.data;

          if(loadType !== 'pdf'){
            this.setPagination();
          }
          
          this.setledgerHeadData();

          const data = cloneDeep(this.ledgerData);
          data.ledgerData = [];

          if(loadType === 'pdf'){
            data.ledgerData = res.data && res.data.ledgers ? res.data.ledgers : [];
          }
          
          data.payload = this.getPayload();

          this.$emit('onLoadData', {data, loadType});
        }
      } catch (err) {
        if (!err.response) {
          this.showError("Something is wrong. Check your connectivity!!");
        }
        if (err.response?.data) {
          this.showError(err.response.data.message);
          return;
        }
      } finally {
        this.reportLoading = false;
      }
    },

    getPayload(){
      const payload = {
        startDate: this.start,
        endDate: this.end,
        accountHead: ''
      }

      const selectedHead = this.accountHeads.find(head => head.id === this.headId);
      if(selectedHead){
        payload.accountHead = selectedHead.name
      }

      return payload;
    },

    onOpenContactSearchModal () {
        this.$store.state.isModalOpenThree = true;
    },

    onSearchContact (contact) {
        this.contactProfile = contact
    }
  },

  setup() {
    const showError = inject("showError");
    const showSuccess = inject("showSuccess");
    const store = useStore()

    const {
            fetchDaybook,
            reportLoading,
            fetchAccountHeads,
            fetchHome,
            getLedgerOld,
            getLedger
          } = handleReport();

    const { commaFormat } = figureFormatter();

    const { fetchContactProfiles } = handleContact();
    const { formatPatientLabel } = useAsyncDropdownHelper();

    const { fetchBusinessList } = handleCBusinesses();
    const { fetchProjects } = handleProjects()
    const { fetchCostCentreList } = handleCostCentres();
    const { fetchContacts } = handleImportTracker();
    const { fetchCompanyDefaultRoles } = handleRole();
    const {fetchTagRegisterList} = handleTagRegister()

    return {
      // journals: computed(() => store.state.journal.journals),
      fetchContactProfiles,
      fetchBusinessList,
      fetchProjects,
      fetchCostCentreList,
      fetchTagRegisterList,
      fetchHome,
      fetchDaybook,
      reportLoading,
      fetchAccountHeads,
      showError,
      showSuccess,
      store,
      getLedgerOld,
      getLedger,
      commaFormat,
      fetchContacts,
      formatPatientLabel,
      fetchCompanyDefaultRoles
    };
  },

  mounted() {
    let companyId = this.$route.params.companyId
    let companyQuery = '?company_id=' + companyId;
    let projectQuery = companyQuery + '&with_donors=1';

    Promise.all([
      this.fetchContacts(companyQuery).then(res => {
        if (res.data) {
          this.contactList = res.data;
        }
      }),
      this.fetchProjects(projectQuery).then(res => {
        if (res.data) {
          this.projects = res.data
        }
      }),
      this.fetchCostCentreList(companyQuery).then(res => {
        if (res.data) {
          this.costCentres = res.data
        }
      }),
      this.fetchTagRegisterList(companyQuery).then(res => {
        if(res.data) {
          this.tagRegisters = res.data;
        }
      }),
      this.resetPagination(),
      this.getAccountHeads(),
      this.getHome(),
      this.getBusinesses(),
      this.getCompanyRoles()
    ]).catch(err => {
      this.showError(err.message)
    })

    if (this.$route.query.headId) {
      this.headId = parseInt(this.$route.query.headId);
      this.onClickGo();
    }
  },
};
</script>

<style scoped>
.head td {
  text-align: right;
}

.head_left td{
  text-align: left;
}
</style>
